<template>
    <div>
        <b-row v-if="isAlertVisisble">
            <b-col
                cols="12"        
            >
                <b-alert
                    class="py-1 px-1"
                    variant="primary"
                    show
                >
                    <div class="d-flex justify-content-between">
                        <h4 class="text-primary">{{ i18nT(`Information`)}}</h4>
                        <span class="cursor-pointer" @click="closeAlert">
                            <RtIcon variant="light" icon="circle-xmark"/>
                        </span>
                    </div>
                    <p class="mb-1">{{ i18nT(`Vous pouvez importer un fichier au format CSV, Excel ou Open Office. Veuillez utiliser les modèles suivants pour faire l'import :`)}}</p>
                    <ul>
                        <li>
                            <b-link>{{ i18nT(`Modèle de document Excel`)}}</b-link>
                        </li>
                        <li>
                            <b-link>{{ i18nT(`Modèle de document Open Office`)}}</b-link>
                        </li>
                        <li>
                            <b-link>{{ i18nT(`Modèle de document CSV`)}}</b-link>
                        </li>
                    </ul>    
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col
                cols="12"
            >
                <b-card>
                    <b-card-header class="border-bottom pt-0">
                        <h4 class="card-title">{{ i18nT(`Importation`)}}</h4>
                    </b-card-header>
                    <b-card-body>
                        <b-row class="pt-3">
                            <b-col cols="12" md="2" class="align-items-center">
                                <h5 class="mt-0">
                                    {{ i18nT(`Fichier`) }}
                                </h5>
                            </b-col>
                            <b-col cols="12" md="10">
                                <b-form-file
                                    ref="filePicker"
                                />
                                <small class="pt-3">
                                    {{ i18nT(`Formats acceptés: xlsx, csv, ods`) }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="pb-0 pr-0 text-right ">
                        <b-button class="mr-1" :to="{name: 'tenants'}">
                            {{i18nT(`Annuler`)}}
                        </b-button>
                        <b-button variant="primary">
                            {{i18nT(`Valider`)}}
                        </b-button>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,

} from 'bootstrap-vue'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BRow,
        BCol,
        BCollapse,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BFormCheckboxGroup,
    },
    data() {
        return {
            isAlertVisisble: true
        }
    },
    methods: {
        closeAlert() {
            this.isAlertVisisble = false
        }
    }
}



</script>

<style>

</style>